import $ from "jquery";

const $externalLink = $(".RichTextExtLink,.ExternalLink");

$externalLink.each((index, link) => {
  const linkLabel = link.innerHTML;
  const linkLabelWrapper = `
    <svg role="img" aria-hidden="true" class="icon icon--svg" viewBox="0 0 32 32" width="32" height="32">
      <use xlink:href="#svg__icon-external" />
    </svg>
    <span class="link-label">${linkLabel}</span>
  `;

  link.innerHTML = linkLabelWrapper;
});
