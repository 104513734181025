import $ from "jquery";

$(document).ready(() => {

    // set container with backgroundcolor for tabrow
    function setHeadlineHeight() {

        var $highestHeadline = 0;
        var $highRow = $('.horizontal--tab-headlinerow').height();

        $('.horizontal--tab-headlinerow').find('.horizontal--tab-opener').each(function() {
            if ($(this).height() > $highestHeadline) {
                $highestHeadline = $(this).height();
            }
        });

        $('.horizontal--tab').find('.sidebar').css({
            'margin-top': $highestHeadline
        });

        $('.horizontal--tab-headlinerow').find('.horizontal--headlinerow-colored').remove();
        $('.horizontal--tab-headlinerow').prepend('<div class="horizontal--headlinerow-colored" style="height: ' + $highRow + 'px;"></div>');

    }; // setHeadlineHeight


    // set ID and AIRA attributes by using attribut 'data-toggleid'
    function setToggleId() {

        $('.horizontal--tab').find('.horizontal--tab-opener').each(function() {
            var $toggleId = $(this).attr('data-toggleid');

            $(this).attr({
                'aria-controls': $toggleId
            });        

            $(this).next().attr({
                'id': $toggleId
            });
        });

        $('.horizontal--tab').find('.content').prepend('<div class="horizontal--tab-headlinerow"></div>');
        $('.horizontal--tab-opener').detach().prependTo('.horizontal--tab-headlinerow');
        $('.horizontal--tab-headlinerow').next().attr({
            'aria-live': 'polite'
        });

    } // setToggleId


    // initialize aria attributs + inline styles
    function setInitial() {

        $('.horizontal--tab').find('.content--tab-text').each(function() {
            $(this).attr('aria-expanded', 'false');       
        });

        $('.horizontal--tab-headlinerow').find('.horizontal--tab-opener').each(function() {
            $(this).attr({
                'aria-expanded': 'false',
                'role': 'button'
            });        
        });

    }; // setInitial


    // if 'data-extended' is true: initialize aria attributs + inline styles
    function setInitialData() {

        $('.horizontal--tab-headlinerow').find('.horizontal--tab-opener').each(function() {
            if ($(this).attr('data-expanded') == 'true') {
                var $toggleId = $(this).attr('data-toggleid');
                var $toggleElement = $('.horizontal--tab').find('#' + $toggleId);

                setInitial(); // initialize aria attributs + inline styles

                $(this).attr({
                    'aria-expanded': 'true'
                });

                $toggleElement.attr({
                    'aria-expanded': 'true'
                });
            }
        });

    }; // setInitialData


    // toggle function on keypress
    $('.horizontal--tab-opener').on('keypress',function(e) {

        var $toggleId = $(this).attr('data-toggleid');
        var $toggleElement = $('.horizontal--tab').find('#' + $toggleId);

        if(e.which == 13) {
            if ($(this).attr('aria-expanded') == 'false') {
                setInitial();
    
                $(this).attr({
                    'aria-expanded': 'true'
                });

                $toggleElement.attr({
                    'aria-expanded': 'true'
                });
            }
// closing tab is not allowed, if needed, use this:
            // else {
            //     setInitial();
            // }
// + + + + + + + + + + + + + + + + + + + + + + + + +
        }

    });


    // opens toggle depending on URL
    function setToggle($anchor) {

        setInitial(); // initialize aria attributs + inline styles

        $('.horizontal--tab').find('#' + $anchor).attr({
            'aria-expanded': 'true'
        });

        $('.horizontal--tab-headlinerow').find('.horizontal--tab-opener').each(function() {
            var $toggleId = $(this).attr('data-toggleid');
            if ($toggleId == $anchor) {
                $(this).attr({
                    'aria-expanded': 'true'
                });               
            }
        });

    }; // setToggle


    // needed for evaluation of the url for toggle the correct content
    function getToggleId() {

        var $anchor = location.href.split("#")[1];
        if ($anchor) {
            setToggle($anchor);
        }

    }; // getToggleId


    // toggle function
    $('.horizontal--tab-opener').click(function() {
        event.preventDefault();

        var $toggleId = $(this).attr('data-toggleid');
        var $toggleElement = $('.horizontal--tab').find('#' + $toggleId);

        if ($toggleElement.attr('aria-expanded') == 'false') {
            setInitial(); // initialize aria attributs + inline styles

            $(this).attr({
                'aria-expanded': 'true'
            });

            $toggleElement.attr({
                'aria-expanded': 'true'
            });
        } 
// closing tab is not allowed, if needed, use this:
        // else {
        //     setInitial(); // initialize aria attributs + inline styles
        //     $(this).attr({
        //         'aria-expanded': 'false'
        //     });
        //     $toggleElement.attr({
        //         'aria-expanded': 'false'
        //     });
        // }
// + + + + + + + + + + + + + + + + + + + + + + + + +

    }); // toggle function click(function()


    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {

        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };

    }; // debounce


    // resize window function with delay
    var myEfficientFn = debounce(function() {
        setHeadlineHeight();    // set container with backgroundcolor for tabrow
    }, 250);
    window.addEventListener('resize', myEfficientFn);


    setToggleId();          // set ID by using attribut 'data-toggleid'
    setInitial();           // initialize aria attributs + inline styles
    setInitialData();       // if 'data-extended' is true: initialize aria attributs + inline styles
    getToggleId();          // get url parameter for toggle content
    setHeadlineHeight();    // set container with backgroundcolor for tabrow
});
