import "../../base/src/jquery-global";
import "mediaelement/full";
import "mediaelement/build/lang/de";
import "mediaelement/build/mejs-controls.svg";
import "mediaelement-plugins/dist/quality/quality";
import "mediaelement-plugins/dist/a11y/a11y";
import "mediaelement-plugins/dist/a11y/a11y-i18n";

const wrapper = $(".mejs__video");
const iconSprite = wrapper.data("icon-sprite");

wrapper.mediaelementplayer({
  iconSprite,
  features: [
    "playpause",
    "progress",
    "duration",
    "tracks",
    "a11y",
    "volume",
    "fullscreen"
  ],
  autoGenerate: true,
  stretching: "auto"
});
