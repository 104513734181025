import $ from "jquery";

$(document).ready(() => {
  document.addEventListener("keydown", function(e) {
    if (e.key === "Tab") {
      $("body").addClass("show-focus-outlines");
    }
  });

  document.addEventListener("click", function() {
    $("body").removeClass("show-focus-outlines");
  });
});
