import $ from "jquery";
import "devbridge-autocomplete";

const $autosuggest = $("input[data-autosuggest]");

$autosuggest.each((index, element) => {
  const $autosuggestUrl = $(element).data("autosuggest");
  const $autosuggestContainer = $(element).parent().find(
    ".autosuggest-container"
  );

  const defaults = {
    serviceUrl: $autosuggestUrl,
    paramName: "userQuery",
    dataType: "json",
    width: false,
    transformResult: function(response) {
      return {
        suggestions: $.map(response.suggestions, function(key) {
          return {
            value: key.name
          };
        })
      };
    },
    minChars: 3,
    lookupLimit: 5
  };

  const autosuggestContainer = {
    appendTo: $autosuggestContainer
  };

  if ($autosuggestContainer.length > 0) {
    $.extend(defaults, autosuggestContainer);
  }

  $(element).autocomplete(defaults);
});
