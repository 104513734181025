import $ from "jquery";

$(document).ready(() => {
  var animateResults = function() {
    $("#hits--count").each(function() {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text()
          },
          {
            duration: 1000,
            easing: "swing",
            step: function(now) {
              let parts = now.toString().split(".");

              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              $(this).text(parts[0]);
            }
          }
        );
    });
  };

  var scrollSearch = function(toElem) {
    var $inputSearch = $("input.input--search");
    var inputOk = false;

    if ($.trim($inputSearch.val()) !== "") {
      inputOk = true;
      // console.log("is set 8");
    }

    if ($(".btn--filter-footer").length || inputOk) {
      // console.log("I am here");
      var $scrollTo = $("#hits--count");
      var scrollPosition = $scrollTo.offset().top;

      $("body,html")
        .stop()
        .animate(
          {
            scrollTop: scrollPosition - 20
          },
          400
        );
    } else {
      return false;
    }
  };

  var accordion = function() {
    const offsetWindowTop = 50;

    // selectors
    var accordionTitle = ".accordion__trigger";
    var accordionPanel = ".accordion__panel";

    // jQuery-Objects
    var $accordionTitle = $(accordionTitle);
    var $accordionPanel = $(accordionPanel);

    // classes
    var activeClass = "js--active";

    var toggleAccordionProperties = function($element, isActive, callback) {
      $element
        .toggleClass(activeClass, isActive)
        .attr("aria-expanded", isActive)
        .parent()
        .next($accordionPanel)
        .attr("aria-hidden", !isActive)
        [`slide${isActive ? "Down" : "Up"}`](300, callback);
    };

    var onButtonClick = function(e) {
      const $currentAccordionTitle = $(e.currentTarget);
      const isCurrentTitleActive = $currentAccordionTitle.hasClass(activeClass);
      const scrollActiveAccordionIntoView = () => {
        const currentAccordionTop = $($currentAccordionTitle).offset().top;
        const scrollTop = currentAccordionTop - offsetWindowTop;
        $("html, body").animate({ scrollTop }, 300);

        // set min-height for accordion body with sidebar
        const currentAccordionSidebar = $(this).parents(".accordion__title").next().find(".accordion__sidebar");
        if (currentAccordionSidebar.length) {
          let sidebarHeight = parseInt(currentAccordionSidebar.height());
          $(this).parents(".accordion__title").next().find(".module-article").attr( "style", "min-height:" + sidebarHeight + "px;");
        }
      };

      // Close all other accordionTitles
      if (!isCurrentTitleActive) {
        toggleAccordionProperties($accordionTitle, false);
      }

      toggleAccordionProperties(
        $currentAccordionTitle,
        !isCurrentTitleActive,
        scrollActiveAccordionIntoView
      );
    };

    $($accordionTitle).on("click", onButtonClick);
  };

  var extractGetParameter = function(string) {
    var ret = {};
    var url = string.substring(1);
    var params = url.split("&");

    for (var i in params) {
      var temp = params[i].split("=");
      ret[temp[0]] = temp[1];
    }
    // console.log(ret);
    return ret;
  };

  var openOnLoad = function(search) {
    var get = extractGetParameter(search);
    if (get.cms_artId !== undefined) {
      $('[data-target="#collapse' + get.cms_artId + '"]').trigger("click");
    }
  };

  accordion();
  scrollSearch();
  animateResults();

  openOnLoad(window.location.search);
});
