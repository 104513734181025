import $ from "jquery";

var TARGET;
var $BTN = $(".rich--text").find("sup");
var getDistance;
var supArray = new Array();

$BTN.each(function(index) {
  var arrayItem = $(this).text();
  if (!String.prototype.includes) {
    if (supArray.indexOf(arrayItem) > -1) {
      $(this).attr("data-index", "down" + arrayItem);
      $(this).attr("data-link", "up" + arrayItem);
    } else {
      $(this).attr("data-index", "up" + arrayItem);
      $(this).attr("data-link", "down" + arrayItem);
      supArray.push(arrayItem);
    }
  } else {
    if (supArray.includes(arrayItem)) {
      $(this).attr("data-index", "down" + arrayItem);
      $(this).attr("data-link", "up" + arrayItem);
    } else {
      $(this).attr("data-index", "up" + arrayItem);
      $(this).attr("data-link", "down" + arrayItem);
      supArray.push(arrayItem);
    }
  }
});

var gotocontent = function(ev) {
  TARGET = $(this).attr("data-index");
  getDistance = 50;

  var $find = $("[data-link=" + TARGET + "]");
  $find.removeClass("animate-it");

  var animateIt = function() {
    $find.addClass("animate-it");
  };

  if ($find.length > 0) {
    $("body, html").animate(
      {
        scrollTop: $find.offset().top - getDistance
      },
      800,
      animateIt()
    );
  }
};

$BTN.bind("click", gotocontent);
