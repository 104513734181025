import $ from "jquery";
import Splide from "@splidejs/splide";

var elms = document.getElementsByClassName("splide");

for (var i = 0; i < elms.length; i++) {
  new Splide(elms[i], {
    classes: {
      arrows: "splide__arrows",
      arrow: "splide__arrow",
    },
    type: "slide",
    perPage: 3,
    pagination: false,
    gap: "30px",
    breakpoints: {
      1040: {
        perPage: 3,
      },
      768: {
        perPage: 2,
        gap: "15px",
      },
      480: {
        perPage: 1,
      },
    },
  }).mount();
}
