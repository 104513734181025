import $ from "jquery";
import jquery from "jquery";

import "@bmwi/base/src/_index";

import "@bmwi/toggle/src/_index";
import "@bmwi/mainnav/src/_index";
import "@bmwi/loginnav/src/_index";
import "@bmwi/sidebar/src/_index";
import "@bmwi/service/src/_index";
import "@bmwi/slider/src/_index";
import "@bmwi/lightbox/src/_index";
import "@bmwi/tooltip/src/_index";
import "@bmwi/trip-tool/src/_index";
import "@bmwi/totop/src/_index";
import "@bmwi/sub-sup/src/_index";
import "@bmwi/tabaccordion/src/_index";
import "@bmwi/autosuggest/src/_index";
import "@bmwi/ticker/src/_index";
import "@bmwi/link/index";
import "@bmwi/form/src/_index";
import "@bmwi/video/index";
import "@bmwi/jumbotrons/src/_index";
import "@bmwi/decisionfinder/src/_index";
import "@bmwi/hero-slider/src/_index";

window.jQuery = $;
