import 'jquery'
import ActionOutside from 'action-outside'

export default class LoginNav {
  constructor(config = {}) {
    let self = this

    self.config = {
      moduleSelector: '.login-nav',
      moduleModifierCollapsed: 'js-collapsed',
      toggleSelector: '.login-nav__toggle',
      closeSelector: '.list__separator',
    }

    self.config = Object.assign(self.config, config)

    self.state = {
      collapsed: true
    }

    self.listener()
    self.style()
  }

  listener() {
    let self = this

    $(self.config.toggleSelector).on('click', () => {
      self.toggle()
    })

    $(self.config.closeSelector).on('click', () => {
      self.close()
    })

    $(self.config.closeSelector).on('keypress', (event) => {
      if ( event == 13 || event == 27) {
        self.close()
      }
    })

    self.actionOutside = new ActionOutside($(self.config.moduleSelector)[0], () => {
      self.close()
    })
  }

  style() {
    let self = this

    if (self.state.collapsed) {
      $(self.config.moduleSelector).addClass(self.config.moduleModifierCollapsed);
      $(self.config.moduleSelector).find(".login-nav__toggle").attr('aria-expanded', 'false');
      $(self.config.moduleSelector).find(".login-nav__panel").attr('aria-hidden', 'true');
    } else {
      $(self.config.moduleSelector).removeClass(self.config.moduleModifierCollapsed);
      $(self.config.moduleSelector).find(".login-nav__toggle").attr('aria-expanded', 'true');
      $(self.config.moduleSelector).find(".login-nav__panel").attr('aria-hidden', 'false');
    }
  }

  toggle() {
    let self = this
    self.state.collapsed = !self.state.collapsed
    self.actionOutside.listen(!self.state.collapsed)
    self.style()
  }

  open() {
    let self = this
    self.state.collapsed = false
    self.actionOutside.listen(true)
    self.style()
  }

  close() {
    let self = this
    self.state.collapsed = true
    self.actionOutside.listen(false)
    self.style()
  }
}
