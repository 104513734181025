import $ from "jquery";
import "magnific-popup";
import "../../base/src/jquery-global";
import "mediaelement/full";
import "mediaelement/build/lang/de";
import "mediaelement/build/mejs-controls.svg";
import "mediaelement-plugins/dist/quality/quality";
import "mediaelement-plugins/dist/a11y/a11y";
import "mediaelement-plugins/dist/a11y/a11y-i18n";

$(".open-popup-link").each(function() {
  // the containers for all your galleries
  $(this).magnificPopup({
    items: {
      src:
        '<div id="mejs__popup" class="white-popup"><div class="mejs__popup-inner"><video class="mejs__video__lightbox" id="id-123" controls="controls" style="width: 100%; height: 100%" width="100%" height="100%" preload="false" data-options="startVolume:1;loop:false;alwaysShowControls:true" data-icon-sprite="/static/GLOBALS/images/mejs-controls.svg" poster="' +
        $(this).attr("data-thumb") +
        '"><source type="video/mp4" src="' +
        $(this).attr("href") +
        '"><track kind="' +
        $(this).attr("data-track-kind") +
        '" label="' +
        $(this).attr("data-track-label") +
        '" srclang="' +
        $(this).attr("data-track-srclang") +
        '" src="' +
        $(this).attr("data-track-src") +
        '"></video></div></div>',
      type: "inline"
    },
    closeBtnInside: true,
    closeMarkup:
      '<button title="Schliessen" type="button" tabindex="1" class="mfp-close btn btn--primary"><span class="btn__label mfp-close">Schliessen</span></span><svg class="icon icon--svg mfp-close" viewBox="0 0 32 32" width="32" height="32"><use class="mfp-close" xlink:href="#svg__icon-close"></use></svg></button>',
    callbacks: {
      open: function() {
        const wrapper = $(".mejs__video__lightbox");
        const iconSprite = wrapper.data("icon-sprite");
        wrapper.mediaelementplayer({
          iconSprite,
          features: [
            "playpause",
            "progress",
            "duration",
            "tracks",
            "a11y",
            "volume",
            "fullscreen"
          ],
          autoGenerate: true,
          stretching: "auto"
        });

        setTimeout(() => {
          $(".mfp-bg").css("opacity", "0.5");
          $(".mejs__container").css("width", "100%");
        }, "20000");

        $(".mfp-wrap, .mejs__container").animate(
          {
            opacity: "1"
          },
          "8000"
        );
      }
    }
  });
});
