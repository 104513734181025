import $ from "jquery";

$(document).ready(() => {
  const pixelPerSecond = 50;

  const $ticker = $(".ticker-paragraph");
  const $wrapper = $ticker.closest(".jumbotron-ticker");
  const tickerWidth = $ticker.width();
  const $pauseButton = $(".js-pause-button");
  const title = $pauseButton.attr("title");
  const title2 = $pauseButton.data("title");
  const ariaLabel = $pauseButton.attr("aria-label");
  const ariaLabel2 = $pauseButton.data("aria-label");
  const PAUSE_STATE_CLASS = "js-ticker-pause";

  $ticker.css({"animation-duration": `${1/pixelPerSecond*tickerWidth}s`})

  $pauseButton.on("click", () => {
    $wrapper.toggleClass(PAUSE_STATE_CLASS);

    if ($wrapper.hasClass(PAUSE_STATE_CLASS)) {
      $pauseButton.attr("title", title);
      $pauseButton.attr("data-title", title2);
      $pauseButton.attr("aria-label", ariaLabel);
      $pauseButton.attr("data-aria-label", ariaLabel2);
    } else {
      $pauseButton.attr("title", title2);
      $pauseButton.attr("data-title", title);
      $pauseButton.attr("aria-label", ariaLabel2);
      $pauseButton.attr("data-aria-label", ariaLabel);
    }
  });

  $ticker.on("animationiteration", () => {
    $wrapper.addClass("js-ticker-animation-repeat");
  });

});
