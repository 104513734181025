import $ from "jquery";

/*
 * set hero picture as background (BMWIUEH-30089)
 */

$(document).ready(() => {
  const heroClass = ".hero-slider--news";
  const heroImgSrcset = $(heroClass).find(".hero-picture img").attr('srcset');
  const heroImgSrc = $(heroClass).find(".hero-picture img").attr('src');
  let $heroBackground = heroImgSrc;

  if(heroImgSrcset) {
    $heroBackground = heroImgSrcset;
  }

  $(heroClass).attr("style", `background-image: url(${$heroBackground});`);
});
