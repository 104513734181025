import $ from "jquery";

const $toTop = $(".to-top");
const $toTopButton = $toTop.find(".uplink");

$(window).scroll(function() {
  if ($(window).scrollTop() > 300) {
    $toTop.fadeIn();
  } else {
    $toTop.fadeOut();
  }
});

$toTopButton.on("click", event => {
  event.preventDefault();
  $("html, body").animate({
      scrollTop: 0
    },
    300
  );
});




// TICKET https://issues.init.de/browse/BMWIUEH-12976
// 

var TARGET,
  $BTN = $('.jump-to-target'),
  getDistance;

const gotocontent = function(ev) {
  ev.preventDefault();

  TARGET = $(this).data('target');
   // console.log("✔✔" + TARGET);

  getDistance = 2;

  const $find = $(TARGET).first();

  if ($find.length > 0) {
    $('body, html').animate({
      scrollTop: $find.offset().top + getDistance
    }, 800);
  }
};

$BTN.on('click', gotocontent);