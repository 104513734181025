import $ from "jquery";

$(document).ready(() => {
  function jsCookie() {
    var $toProp = $("#urlgetter"),
      $toEnable = $("#toenable"),
      $signContainer = $(".content-sign"),
      openUrl = $toEnable.data("url"),
      cookieText = $toEnable.data("cookie-text"),
      loadingText = $toEnable.data("loading-text"),
      numberDays = $toEnable.data("number-days"),
      validationText = $toEnable.data("validation");

    function propit() {
      if ($signContainer.length === 0) return false;

      if (checkCookie()) {
        console.log("there is a cookie");
        $signContainer.addClass("cookie-enable");
        $signContainer.html(
          "<p>" +
            cookieText +
            "</p><a class='btn btn-primary' href='" +
            openUrl +
            "' target='_blank' rel='noopener'><span class='btn-label'>Zum Direktantrag</span><span class='btn-icon icon icon-arrow-right'></span></a>"
        );
      } else {
        $toProp.bind("change", function() {
          if ($(this).prop("checked") == true) {
            $toEnable.removeClass("disabled");
            $toEnable
              .children(".icon-close")
              .removeClass("icon-close")
              .addClass("icon-arrow-right");
            $toEnable
              .attr("href", openUrl)
              .attr("target", "_blank")
              .attr("rel", "noopener");
          } else {
            console.log("is false");
          }
        });
        $toEnable.on("click", function() {
          if ($(this).hasClass("disabled")) {
            $(
              "<p class='validation'>" + validationText + "</span>"
            ).insertBefore("#anker-to-validation");
          } else {
            setCookie("ubhpermission", "ok", numberDays);
            $(this).addClass("loading");
            $(this)
              .children(".icon")
              .removeClass("icon-arrow-right")
              .addClass("icon-tick");
            $(this)
              .children(".btn-label")
              .html(loadingText);
          }
        });
      }
    }

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function checkCookie() {
      var user = getCookie("ubhpermission");
      if (user != "") {
        return true;
      } else {
        return false;
      }
    }

    propit();
  }

  jsCookie();
});
