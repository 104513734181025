import $ from "jquery";
import "select2";

$(function() {
  $("select").select2({
    minimumResultsForSearch: Infinity,
    width: "resolve"
  });
});

function searchHits() {
  var count = $(".form-hidden-hits");
  if (count.length > 0) {
    var animatecount;
    var countValue = $(".form-hidden-hits").text();
    var options = {
      useEasing: true,
      useGrouping: true,
      separator: $("html").attr("lang") === "en" ? "," : ".",
      decimal: ".",
      prefix: "",
      suffix: ""
    };

    var animatecount = new countUp(
      "hits-count",
      0,
      countValue,
      0,
      2.5,
      options
    );
    animatecount.start();
  }
}
searchHits();


// countUp.min.js
function countUp(a, b, c, d, e, f) {
  this.options = f || {
    useEasing: !0,
    useGrouping: !0,
    separator: ",",
    decimal: "."
  };
  for (
    var g = 0, h = ["webkit", "moz", "ms"], i = 0;
    i < h.length && !window.requestAnimationFrame;
    ++i
  )
    (window.requestAnimationFrame = window[h[i] + "RequestAnimationFrame"]),
      (window.cancelAnimationFrame =
        window[h[i] + "CancelAnimationFrame"] ||
        window[h[i] + "CancelRequestAnimationFrame"]);
  window.requestAnimationFrame ||
    (window.requestAnimationFrame = function(a) {
      var c = new Date().getTime(),
        d = Math.max(0, 16 - (c - g)),
        e = window.setTimeout(function() {
          a(c + d);
        }, d);
      return (g = c + d), e;
    }),
    window.cancelAnimationFrame ||
      (window.cancelAnimationFrame = function(a) {
        clearTimeout(a);
      });
  var j = this;
  (this.d = "string" == typeof a ? document.getElementById(a) : a),
    (this.startVal = Number(b)),
    (this.endVal = Number(c)),
    (this.countDown = this.startVal > this.endVal ? !0 : !1),
    (this.startTime = null),
    (this.timestamp = null),
    (this.remaining = null),
    (this.frameVal = this.startVal),
    (this.rAF = null),
    (this.decimals = Math.max(0, d || 0)),
    (this.dec = Math.pow(10, this.decimals)),
    (this.duration = 1e3 * e || 2e3),
    (this.easeOutExpo = function(a, b, c, d) {
      return (1024 * c * (-Math.pow(2, (-10 * a) / d) + 1)) / 1023 + b;
    }),
    (this.count = function(a) {
      null === j.startTime && (j.startTime = a), (j.timestamp = a);
      var b = a - j.startTime;
      if (((j.remaining = j.duration - b), j.options.useEasing))
        if (j.countDown) {
          var c = j.easeOutExpo(b, 0, j.startVal - j.endVal, j.duration);
          j.frameVal = j.startVal - c;
        } else
          j.frameVal = j.easeOutExpo(
            b,
            j.startVal,
            j.endVal - j.startVal,
            j.duration
          );
      else if (j.countDown) {
        var c = (j.startVal - j.endVal) * (b / j.duration);
        j.frameVal = j.startVal - c;
      } else
        j.frameVal = j.startVal + (j.endVal - j.startVal) * (b / j.duration);
      (j.frameVal = Math.round(j.frameVal * j.dec) / j.dec),
        (j.frameVal = j.countDown
          ? j.frameVal < j.endVal
            ? j.endVal
            : j.frameVal
          : j.frameVal > j.endVal
          ? j.endVal
          : j.frameVal),
        (j.d.innerHTML = j.formatNumber(j.frameVal.toFixed(j.decimals))),
        b < j.duration
          ? (j.rAF = requestAnimationFrame(j.count))
          : null != j.callback && j.callback();
    }),
    (this.start = function(a) {
      return (
        (j.callback = a),
        isNaN(j.endVal) || isNaN(j.startVal)
          ? (console.log("countUp error: startVal or endVal is not a number"),
            (j.d.innerHTML = "--"))
          : (j.rAF = requestAnimationFrame(j.count)),
        !1
      );
    }),
    (this.stop = function() {
      cancelAnimationFrame(j.rAF);
    }),
    (this.reset = function() {
      (j.startTime = null),
        cancelAnimationFrame(j.rAF),
        (j.d.innerHTML = j.formatNumber(j.startVal.toFixed(j.decimals)));
    }),
    (this.resume = function() {
      (j.startTime = null),
        (j.duration = j.remaining),
        (j.startVal = j.frameVal),
        requestAnimationFrame(j.count);
    }),
    (this.formatNumber = function(a) {
      a += "";
      var b, c, d, e;
      if (
        ((b = a.split(".")),
        (c = b[0]),
        (d = b.length > 1 ? j.options.decimal + b[1] : ""),
        (e = /(\d+)(\d{3})/),
        j.options.useGrouping)
      )
        for (; e.test(c); ) c = c.replace(e, "$1" + j.options.separator + "$2");
      return c + d;
    }),
    (j.d.innerHTML = j.formatNumber(j.startVal.toFixed(j.decimals)));
}
