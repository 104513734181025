import $ from "jquery";

$(document).ready(() => {
  var navMobileOpener = $(".nav--main .opener--mobile"),
    closeButton = $(".link--close"),
    navMetroLink = $(".open-flyout"),
    navbarLayer = $(".mainnav__flyout");

  var toggleMobileMenu = function(event) {
    event.preventDefault();
    closeNavMetroSecond(event)
    $(this)
      .parent()
      .toggleClass("expanded");
  };

  var closeNavMetroSecond = function(event) {
    // for all

    event.preventDefault();
    navMetroLink.removeClass("active");
    navbarLayer.removeClass("active");
    // navMetroFirstLevel.removeClass("addBorder");
    navbarLayer.css("animation", "openLayerMD 1s");
    return false;
  };

  var openCloseNavMetroSecond = function(event) {
    // for all
    event.preventDefault();

    var dataOpener = $(this).data("opener"),
      toOpen = $(".mainnav__flyout--" + dataOpener),
      mq = window.matchMedia("(max-width: 480px)");

    if (toOpen.hasClass("active")) {
      $(this).removeClass("active");
      navbarLayer.removeClass("active");
      //$('body').removeAttr('class');
    } else {
      navMetroLink.removeClass("active");
      $(this).addClass("active");

      if ($(".mainnav__flyout.active").length) {
        toOpen.css("animation", "openLayerMD 0");
      }

      navbarLayer.removeClass("active");
      toOpen.addClass("active");

      if (mq.matches) {
        event.preventDefault();

        var heightValue = 70;
        $("html, body").animate(
          {
            scrollTop: toOpen.offset().top - heightValue
          },
          1000
        );
      }
    }
  };

  navMobileOpener.on("click", toggleMobileMenu);
  navMetroLink.on("click", openCloseNavMetroSecond);
  closeButton.on("click", closeNavMetroSecond);
});
